import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Paper } from '@mui/material';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectNavbarTablet } from 'api/redux/NavBarStateReducer';
import { UsersTable } from 'common/components/UsersTable/UsersTable';
import { useLazyGetInvestorUsersQuery } from 'api/redux/services/investors.service';
import { onSetUsers, usersState } from 'api/redux/UsersReducer';
import { styled } from '@mui/system';

const InvestorMemberPaper = styled(Paper)(() => ({
	width: 'min(100%, 1300px)',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	backgroundColor: '#FFFFFF',
	marginLeft: 'auto',
	marginRight: 'auto',
	borderRadius: '5px',
	marginTop: '8px',
}));

export const InvestorMembers: FC = () => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const tablet = useSelector(selectNavbarTablet);
	const [getInvestorUsers, investorUsers] = useLazyGetInvestorUsersQuery();
	const { users, sortStrategy } = useSelector(usersState);
	const [page, setPage] = useState<number>(0);
	const [pages, setPages] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchString, setSearchString] = useState<string>('');
	const { currentInvestor, currentSponsor } = grants.grants;

	const fetchInvestorUsers = () => {
		getInvestorUsers({
			investorId: currentInvestor.id,
			sponsorId: currentSponsor.id,
			page,
			searchString,
			sortStrategy: sortStrategy,
		});
	};

	useEffect(() => {
		if (currentInvestor.id !== 0) {
			setLoading(true);
			fetchInvestorUsers();
		} else {
			dispatch(onSetUsers([]));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentSponsor.id, currentInvestor.id, searchString, page, sortStrategy]);

	useEffect(() => {
		if (investorUsers.data) {
			const { rows: fetchedUsers, pages } = investorUsers.data;
			dispatch(onSetUsers(fetchedUsers));
			setPages(pages);
		}
	}, [dispatch, investorUsers]);

	useEffect(() => {
		const { isError, isSuccess } = investorUsers;

		if (isError || isSuccess) setLoading(false);
	}, [investorUsers]);

	if (!users) return <></>;

	return (
		<InvestorMemberPaper elevation={0}>
			<UsersTable
				refetchUsers={fetchInvestorUsers}
				setPage={setPage}
				pages={pages}
				loading={loading}
				searchString={searchString}
				setSearchString={setSearchString}
			/>
		</InvestorMemberPaper>
	);
};
