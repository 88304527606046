import { useParams, Navigate } from 'react-router-dom';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { ROLES } from 'common/helpers/permissions/Roles';
import ProtectedRoute from './ProtectedRoute';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

interface IGrantsProtectedRouteParams {
	children: JSX.Element;
	roles: string[];
}

const GrantsProtectedRoute = ({
	children,
	roles,
}: IGrantsProtectedRouteParams) => {
	const grants = useSelector(selectDropdown);
	const { currentSponsor } = grants.grants;
	const { allowedSponsor, currentRoles: userRoles } = useAuth0Grants();

	if (!currentSponsor.id) {
		return <Navigate to="/" />;
	}

	if (
		!userRoles.includes(ROLES.SUPER_ADMIN) &&
		!allowedSponsor(currentSponsor.id)
	) {
		return <Navigate to="/" />;
	}

	return <ProtectedRoute roles={roles}>{children}</ProtectedRoute>;
};

export default GrantsProtectedRoute;
