import { Box, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import { useUpdateSponsorMutation } from 'api/redux/services/organizations.service';
import { Sponsor } from 'app/types/ui/organization.types';

interface IEditSponsorProps {
	open: boolean;
	onClose: () => void;
	sponsor: Sponsor;
}

export const EditSponsor: FC<IEditSponsorProps> = ({
	open,
	onClose,
	sponsor,
}) => {
	const [updateSponsor] = useUpdateSponsorMutation();
	const [sponsorName, setSponsorName] = useState<string>(sponsor.name);

	const handleSubmit = async () => {
		await updateSponsor({
			name: sponsorName,
			sponsorId: sponsor.id,
		});
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Update Sponsor</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					label="Sponsor Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={sponsorName}
					onChange={(e) => setSponsorName(e.target.value)}
				/>
			</Box>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" onClick={handleSubmit}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
