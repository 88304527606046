import { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper } from '@mui/material';
import Announcements from './Announcements';
import ActivitySummary from './ActivitySummary';
import Contributions from './Contributions';
import { useAuth0 } from '@auth0/auth0-react';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { selectNavbarMobile } from 'api/redux/NavBarStateReducer';
import { useLazyGetInvestorCapitalActivityQuery } from 'api/redux/services/capitalActivityApi';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ICapitalActivity } from 'modules/reports/CapitalAccountStatement/capital-activity.types';
import {
	onSetRedirectURL,
	redirectURLState,
} from 'api/redux/RedirectURLReducer';
import { Navigate } from 'react-router-dom';
import { useLocalUser } from 'common/helpers/permissions/useLocalUser';
import { styled } from '@mui/system';

const DashboardContainer = styled(Grid)(({ theme }) => ({
	height: '100%',
	margin: '7px 2px 5px 2px',
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
	padding: theme.spacing(2),
	borderRadius: 5,
	height: '100%',
	overflow: 'auto',
	border: '1px solid #D0D7DE',
	marginTop: '-5px',
}));

const ActivitySummaryGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

const ContributionsGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

const AnnouncementsGrid = styled(Grid)(() => ({
	width: 'calc(100vw - 100px)',
}));

export const Dashboard: FC = () => {
	const grants = useSelector(selectDropdown);
	const mobile = useSelector(selectNavbarMobile);
	const { currentInvestor, currentFund } = grants.grants;
	const { currentUser } = useLocalUser();
	const [getCapitalActivity, capitalActivity] =
		useLazyGetInvestorCapitalActivityQuery();
	const [currentCAS, setCurrentCAS] = useState<ICapitalActivity[]>([]);
	const { isAuthenticated, user } = useAuth0();
	const dispatch = useDispatch();
	const backgroundStyle = !currentUser?.initialLogin
		? { filter: 'blur(5px)' }
		: {};
	const redirectURL = useSelector(redirectURLState);

	useEffect(() => {
		if (currentFund.id === 0 || currentInvestor.id === 0) {
			setCurrentCAS([]);
			return;
		}

		getCapitalActivity({
			investorId: currentInvestor.id,
			fundId: currentFund.id,
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor, currentFund]);

	useEffect(() => {
		if (!capitalActivity.data) return;

		setCurrentCAS(capitalActivity.data);
	}, [capitalActivity.data]);

	useEffect(() => {
		const url = sessionStorage.getItem('redirectUrl');

		if (url) {
			sessionStorage.removeItem('redirectUrl');
			dispatch(onSetRedirectURL(url));
		}
	});

	useEffect(() => {
		if (mobile) {
			dispatch(onSetRedirectURL('/documents'));
		}
	}, [dispatch, mobile]);

	if (redirectURL && currentUser?.initialLogin)
		return <Navigate to={redirectURL} />;

	return (
		<MainSidebarContainer>
			<PrivacyPolicy />
			{user && capitalActivity && (
				<DashboardContainer container spacing={1} style={backgroundStyle}>
					<ActivitySummaryGrid
						item
						xs={12}
						sx={{
							height: mobile ? 'calc(100vh/3 - 35px)' : 'calc(100vh/3 - 25px)',
						}}
					>
						<StyledPaper elevation={0}>
							<ActivitySummary capitalActivityData={currentCAS} />
						</StyledPaper>
					</ActivitySummaryGrid>
					<ContributionsGrid
						item
						xs={6}
						sx={{
							height: mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)',
						}}
					>
						<StyledPaper elevation={0}>
							<Contributions capitalActivityData={currentCAS} />
						</StyledPaper>
					</ContributionsGrid>
					<AnnouncementsGrid
						item
						xs={6}
						sx={{
							height: mobile ? 'calc(200vh/3 - 65px)' : 'calc(200vh/3 - 50px)',
						}}
					>
						<StyledPaper elevation={0}>
							<Announcements />
						</StyledPaper>
					</AnnouncementsGrid>
				</DashboardContainer>
			)}
		</MainSidebarContainer>
	);
};

export default Dashboard;
