import { Box, Modal } from '@mui/material';
import { FC, useState } from 'react';
import { ExportModes, IExportUsersCSVModalProps } from './types';
import { ExportMode } from './ExortMode';
import { ExportByRelationship } from './ExportByRelationship';
import { ExportByUser } from './ExportByUser';
import { Button } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';

const BoxContainer = styled(Box)(() => ({
	position: 'absolute',
	display: 'flex',
	flexDirection: 'column',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	overflow: 'auto',
	width: '80vw',
	backgroundColor: '#ffffff',
	boxShadow: '24',
	borderRadius: 10,
}));

export const ExportUsersCSVModal: FC<IExportUsersCSVModalProps> = ({
	open,
	onClose,
}) => {
	const [mode, setMode] = useState<ExportModes>(ExportModes.BY_RELATIONSHIP);
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Modal open={open} onClose={onClose}>
			<BoxContainer>
				<Box display="flex" justifyContent="flex-end">
					<Button
						variant="text"
						onClick={onClose}
						sx={{
							color: `${sponsorColor}`,
						}}
					>
						<CloseIcon />
					</Button>
				</Box>
				<ExportMode
					value={mode}
					handleChange={(mode: ExportModes) => setMode(mode)}
				/>

				<ExportByRelationship visible={mode === ExportModes.BY_RELATIONSHIP} />
				<ExportByUser visible={mode === ExportModes.BY_USER} />
			</BoxContainer>
		</Modal>
	);
};
