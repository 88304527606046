import {
	Button,
	Box,
	Typography,
	LinearProgress,
	TextField,
	IconButton,
	styled,
} from '@mui/material';
import { FC } from 'react';
import { IEditBoxProps, MAX_DISCLAIMER_CHARS } from './UpdateDisclaimerTypes';
import { Close, Edit } from '@mui/icons-material';

const Wrapper = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-end',
	gap: '1em',
});

const Container = styled(Box)({
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
});

const ButtonContainer = styled(Box)({
	marginTop: 15,
	marginRight: 55,
	display: 'flex',
	justifyContent: 'flex-end',
});

const Progress = styled(Box)({
	width: '80%',
	marginLeft: 'auto',
	marginRight: 'auto',
});

export const EditBox: FC<IEditBoxProps> = ({
	show,
	isLoading,
	isEditing,
	setIsEditing,
	disclaimer,
	setDisclaimer,
	handleSubmit,
}) => {
	if (!show) return <></>;

	return (
		<Container>
			<Progress>{isLoading && <LinearProgress color="secondary" />}</Progress>

			<Wrapper>
				<Typography variant="h4" style={{ whiteSpace: 'nowrap' }}>
					Disclaimer
				</Typography>
				<TextField
					variant="outlined"
					fullWidth
					inputProps={{
						maxLength: MAX_DISCLAIMER_CHARS,
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={disclaimer}
					onChange={(event) => setDisclaimer(event.target.value)}
				/>
				<IconButton onClick={() => setIsEditing(!isEditing)}>
					{isEditing ? <Close /> : <Edit />}
				</IconButton>
			</Wrapper>

			<ButtonContainer>
				<Button variant="contained" onClick={handleSubmit}>
					Save
				</Button>
			</ButtonContainer>
		</Container>
	);
};
