import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IViewInvestorInfoButtonProps } from './types';
import { onChangeInvestor, selectDropdown } from 'api/redux/DropdownReducer';
import { IInvestorGrant } from 'api/redux/services/userApi';
import { IconBtn } from 'common/components/IconBtn';
import { ERoutes } from 'App';
import { VisibilityOutlined } from '@mui/icons-material';

export const ViewInvestorInfoButton: FC<IViewInvestorInfoButtonProps> = ({
	show,
	investorId,
}) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const grants = useSelector(selectDropdown);
	const { allAvailableInvestors } = grants.grants;

	if (!show || !investorId) return <></>;

	const findInvestorGrant = (
		investorId: number,
	): IInvestorGrant | undefined => {
		return allAvailableInvestors.find((grant) => grant.id === investorId);
	};

	const redirectToInvestorPage = () => {
		const investor = findInvestorGrant(investorId);

		dispatch(onChangeInvestor(investor));
		navigate(ERoutes.InvestorsView);
	};

	return (
		<IconBtn
			customColor={true}
			onClick={redirectToInvestorPage}
			tooltip="View Investor Info"
		>
			<VisibilityOutlined />
		</IconBtn>
	);
};
