import React from 'react';
import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import { CSVLink } from 'react-csv';
import { csvData, csvHeaders } from './types';
import { useGetInvestorsQuery } from 'api/redux/services/investors.service';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useSelector } from 'react-redux';

const CsvButton = styled(Button)(({ theme }) => ({
	backgroundColor: 'white',
	border: '#bfbfbf 1px solid',
	maxWidth: 'fit-content',
	margin: '10px',
	padding: '3px',
}));

const Select = styled(Box)(({ theme }) => ({
	width: '25%',
	padding: '10px',
}));

const SelectLabel = styled(Box)(({ theme }) => ({
	padding: '4px',
}));

export const CSVTemplate: React.FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const { data: investors } = useGetInvestorsQuery(currentFund.id);

	if (!investors) return <></>;

	const csvReport = {
		data: csvData(investors),
		headers: csvHeaders,
		filename: 'CapitalActivityTemplate.csv',
	};

	return (
		<Box>
			<CsvButton>
				<CSVLink {...csvReport}> Download Template </CSVLink>
			</CsvButton>
		</Box>
	);
};

export default CSVTemplate;
