import { FC } from 'react';
import { Typography } from '@mui/material';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { ReportTypeNames } from '../ui/Reports';
import {
	EMPTY_REPORTS_COLLECTION,
	IEdgeCaseMessageProps,
	IMessageParagraphProps,
	REPORT_TYPE_NOT_ALLOWED,
} from './types';
import { styled } from '@mui/system';

const MessageParagraphText = styled(Typography)(() => ({
	paddingRight: '10px',
	color: 'black',
}));

export const EdgeCaseMessage: FC<IEdgeCaseMessageProps> = ({
	investorAllowedReportTypes,
	capitalActivityData,
}) => {
	const grants = useSelector(selectDropdown);
	const { currentInvestor } = grants.grants;
	const { isAdmin } = useAuth0Grants();
	const investorSelected = currentInvestor.id !== 0;
	const hasReportTypePermission = !!investorAllowedReportTypes.find(
		(rt) => rt.name === ReportTypeNames.CAPITAL_ACCOUNT_STATEMENT,
	);

	const MessageParagraph: FC<IMessageParagraphProps> = ({ message }) => {
		return <MessageParagraphText variant="h6">{message}</MessageParagraphText>;
	};

	if (isAdmin && capitalActivityData.length === 0)
		return <MessageParagraph message={EMPTY_REPORTS_COLLECTION} />;

	return investorSelected && !hasReportTypePermission && !isAdmin ? (
		<MessageParagraph
			message={REPORT_TYPE_NOT_ALLOWED(
				ReportTypeNames.CAPITAL_ACCOUNT_STATEMENT,
			)}
		/>
	) : !capitalActivityData?.length || !investorSelected ? (
		<MessageParagraph message={EMPTY_REPORTS_COLLECTION} />
	) : (
		<></>
	);
};
