import { FC } from 'react';
import { MenuItem, InputLabel } from '@mui/material';
import { onChangeFund, selectDropdown } from 'api/redux/DropdownReducer';
import { DropdownFormControl, SelectControl } from '../styles';
import { useDispatch, useSelector } from 'react-redux';
import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';
import { onSetSelectedDocTypeIds } from 'api/redux/DocumentsReducer';

export const FundDropdown: FC = () => {
	const dispatch = useDispatch();
	const { pathname } = useLocation();
	const isOrganizationPage = [
		ERoutes.OrganizationView,
		ERoutes.InvestorsView,
	].includes(pathname as ERoutes);
	const grants = useSelector(selectDropdown);
	const { availableFunds, currentFund, currentSponsor } = grants.grants;

	const FixedAllFunds: FC = () => {
		return (
			<DropdownFormControl
				variant="outlined"
				size="small"
				style={{ margin: 5, width: '200px' }}
			>
				<InputLabel id="fund-selector" style={{ color: '#F1F2F9' }}>
					All Funds
				</InputLabel>
				<SelectControl
					labelId="fund-selector"
					id="fund-selector"
					value={''}
					label="Fund"
					disabled={true}
				>
					<MenuItem key={0} value={0}>
						All Funds
					</MenuItem>
				</SelectControl>
			</DropdownFormControl>
		);
	};

	const DynamicFunds: FC = () => {
		return (
			<DropdownFormControl
				variant="outlined"
				size="small"
				style={{ margin: 5, width: '200px' }}
			>
				<InputLabel id="fund-selector" style={{ color: '#F1F2F9' }}>
					Fund
				</InputLabel>
				<SelectControl
					labelId="fund-selector"
					id="fund-selector"
					value={currentFund.id || ''}
					label="Fund"
				>
					{availableFunds.map((fund, index) => (
						<MenuItem
							onClick={() => {
								dispatch(onChangeFund(fund));
								dispatch(onSetSelectedDocTypeIds([]));
							}}
							key={index}
							value={fund.id}
						>
							{fund.name}
						</MenuItem>
					))}
				</SelectControl>
			</DropdownFormControl>
		);
	};

	if (
		currentSponsor.id !== 0 &&
		availableFunds.length > 0 &&
		!isOrganizationPage
	) {
		return <DynamicFunds />;
	}

	if (isOrganizationPage) return <FixedAllFunds />;

	return <></>;
};
