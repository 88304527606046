import { FC } from 'react';
import { InputLabel } from '@mui/material';
import {
	docsUploadState,
	onSetDate,
	onSetDateConfirmed,
} from 'api/redux/DocsUploadReducer';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from 'modules/documents/utils';
import { styled } from '@mui/system';

const DatePickerWrapper = styled(ReactDatePicker)(() => ({
	border: '1px solid #c0c0c0 !important',
	borderRadius: '4px !important',
	padding: '4px 4px 4px 10px !important',
	width: '100%',
}));

export const DocumentDateSelector: FC = () => {
	const { selectedDocDate } = useSelector(docsUploadState);
	const dispatch = useDispatch();

	return (
		<>
			<InputLabel>Document Date</InputLabel>
			<DatePickerWrapper
				// wrapperClassName={classes.datePickerWrapper} // display: 'flex'
				selected={selectedDocDate ? new Date(selectedDocDate) : undefined}
				onChange={(date) => {
					const today = new Date();
					const selectedDate = date || today;

					dispatch(onSetDate(formatDate(selectedDate.toString())));

					if (
						!(
							selectedDate.getDate() == today.getDate() &&
							selectedDate.getMonth() == today.getMonth() &&
							selectedDate.getFullYear() == today.getFullYear()
						)
					) {
						dispatch(onSetDateConfirmed(true));
					}
				}}
			/>
		</>
	);
};
