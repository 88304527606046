import { ERoutes } from 'App';
import { useLocation } from 'react-router-dom';
import { FC, ReactElement, useEffect } from 'react';
import { SponsorDropdown } from './SponsorDropdown';
import { FundDropdown } from './FundDropdown';
import { InvestorDropdown } from './InvestorDropdown';
import { matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { FlexDirection } from '../types';
import { useUpdateLastLoginMutation } from 'api/redux/services/userApi';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth0Grants } from 'common/helpers/permissions/useGetAuth0Grants';
import { ROLES } from 'common/helpers/permissions/Roles';

export const DropDowns: FC = (): ReactElement => {
	const { pathname } = useLocation();
	const grants = useSelector(selectDropdown);
	const { availableSponsors, currentInvestor, currentFund, currentSponsor } =
		grants.grants;
	const { isAuthenticated, user } = useAuth0();
	const dropdownsFlexDirection: FlexDirection = FlexDirection.ROW;
	const isSpecificDocumentPage = matchPath(pathname, ERoutes.SpecificDocument);
	const isBulkUploadUsersPage = matchPath(pathname, ERoutes.BulkUploadUsers);
	const [updateLastLogin] = useUpdateLastLoginMutation();
	const { currentRoles } = useAuth0Grants();

	useEffect(() => {
		if (
			!isAuthenticated ||
			!user?.sub ||
			currentRoles.includes(ROLES.SUPER_ADMIN) ||
			!currentSponsor.id ||
			currentSponsor.id === 0
		)
			return;

		updateLastLogin({
			sponsorId: currentSponsor.id,
			fundId: currentFund.id,
			investorId: currentInvestor.id,
		});
	}, [
		currentFund.id,
		currentInvestor.id,
		currentSponsor.id,
		isAuthenticated,
		currentRoles,
		updateLastLogin,
		user?.sub,
	]);

	if (
		pathname.includes('admin') ||
		pathname.includes('Super Admin') ||
		!availableSponsors.length ||
		isSpecificDocumentPage ||
		isBulkUploadUsersPage
	)
		return <></>;

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: dropdownsFlexDirection,
				flexWrap: 'wrap',
				justifyContent: 'right',
			}}
		>
			<SponsorDropdown />
			<FundDropdown />
			<InvestorDropdown />
		</div>
	);
};
