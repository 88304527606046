import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Radio,
	RadioGroup,
} from '@mui/material';
import { FC } from 'react';
import { ExportModes, IExportModeProps } from './types';
import { useSelector } from 'react-redux';
import { selectSponsorPrimaryColor } from 'api/redux/siteColorReducer';

export const ExportMode: FC<IExportModeProps> = ({ value, handleChange }) => {
	const sponsorColor = useSelector(selectSponsorPrimaryColor);

	return (
		<Box style={{ display: 'flex', justifyContent: 'center' }}>
			<FormControl>
				<FormLabel
					id="export-modes-label"
					style={{ textAlign: 'center', fontSize: 24, marginBottom: '1rem' }}
				>
					Select Export Mode
				</FormLabel>
				<RadioGroup
					row
					aria-labelledby="export-modes-label"
					name="export-modes"
					value={value}
					onChange={(e) => handleChange(e.target.value as ExportModes)}
					style={{
						display: 'flex',
						justifyContent: 'center',
						color: `${sponsorColor}`,
					}}
				>
					<FormControlLabel
						value={ExportModes.BY_RELATIONSHIP}
						control={<Radio style={{ color: sponsorColor }} />}
						label="Export by Relationship"
					/>
					<FormControlLabel
						value={ExportModes.BY_USER}
						control={<Radio style={{ color: sponsorColor }} />}
						label="Export by User"
					/>
				</RadioGroup>
			</FormControl>
		</Box>
	);
};
