import { Box, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { TextField } from '@mui/material';
import { FC, useState } from 'react';
import { Fund } from 'app/types/ui/fund.types';
import { useUpdateFundMutation } from 'api/redux/services/fundApi';

interface IEditFundProps {
	onClose: () => void;
	fund: Fund;
}

export const EditFund: FC<IEditFundProps> = ({ onClose, fund }) => {
	const [updateFund] = useUpdateFundMutation();
	const [fundName, setFundName] = useState(fund.name);

	const handleSubmit = async () => {
		await updateFund({
			name: fundName,
			fundId: fund.id,
		});
		onClose();
	};

	return (
		<Dialog open={true} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>Edit Fund</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<TextField
					label="Fund Name"
					variant="outlined"
					fullWidth
					inputProps={{
						style: {
							borderBottom: 'none',
							paddingLeft: 20,
						},
					}}
					value={fundName}
					onChange={(e) => setFundName(e.target.value)}
				/>
			</Box>
			<DialogActions>
				<Button onClick={onClose}>Cancel</Button>
				<Button variant="contained" onClick={handleSubmit}>
					Save
				</Button>
			</DialogActions>
		</Dialog>
	);
};
