import { Box } from '@mui/material';
import { styled } from '@mui/system';

export const OptionsColumnBox = styled(Box)(() => ({
	border: 'solid 1px #D0D7DE',
	borderRadius: '10px',
	height: '300px',
	overflowY: 'auto',
	padding: '1rem',
	maxWidth: '80%',
}));
