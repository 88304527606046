/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react';
import {
	Box,
	Modal,
	Typography,
	Button,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Backdrop,
	CircularProgress,
	Alert,
	InputLabel,
	FormControl,
	Select,
	MenuItem,
	Grid,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Dropzone from 'react-dropzone';
import { useUploadCSVMutation } from 'api/redux/services/capitalActivityApi';
import { NotifyCheckbox } from './NotifyCheckbox';
import { NotificationDelaySelector } from 'modules/admin/UploadDocuments/NotificationDelaySelector';
import { docsUploadState } from 'api/redux/DocsUploadReducer';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { styled } from '@mui/system';

const ButtonBox = styled(Box)({
	backgroundColor: 'white',
	border: '#bfbfbf 1px solid',
	maxWidth: 'fit-content',
	margin: '10px',
	padding: '3px',
});

const ModalText = styled(Typography)({
	padding: '20px',
});

const DropZoneBox = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	margin: '20px',
	height: '200px',
	border: '2px dashed black',
});

const ActionsContainer = styled(Grid)(({ theme }) => ({
	marginBottom: theme.spacing(2),
}));

const UploadButton = styled(Button)({
	backgroundColor: 'white !important',
	textTransform: 'inherit',
	height: '30px !important',
	marginTop: '15px !important',
	marginBottom: '15px !important',
	marginLeft: '10px !important',
	width: '150px !important',
	padding: '2px !important',
	fontSize: 14,
	color: '#6558f5 !important',
	border: '1px solid #cfcdf9 !important',
});

const Background = styled(Box)({
	width: '100%',
	height: '100%',
});

const Step = styled(Typography)({
	color: '#FFFFFF',
});

const ButtonContainer = styled(Box)({
	padding: 2,
	width: '100%',
	display: 'flex',
	justifyContent: 'flex-end',
});

const SelectFormControl = styled(FormControl)({
	width: '100%',
	minWidth: 200,
	padding: '10px',
});

const SelectInputLabel = styled(InputLabel)({
	padding: '4px',
});

const ReportingPeriodBox = styled(Box)({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
});

const ReportPeriods = [
	'Period ending March 31, 2020',
	'Period ending June 30, 2020',
	'Period ending September 30, 2020',
	'Period ending December 31, 2020',
	'Period ending March 31, 2021',
	'Period ending June 30, 2021',
	'Period ending September 30, 2021',
	'Period ending December 31, 2021',
	'Period ending March 31, 2022',
	'Period ending June 30, 2022',
	'Period ending September 30, 2022',
	'Period ending December 31, 2022',
	'Period ending December 31, 2022 - DRAFT',
	'Period ending March 31, 2023',
	'Period ending June 30, 2023',
	'Period ending September 30, 2023',
	'Period ending December 31, 2023',
	'Period ending December 31, 2023 - DRAFT',
	'Period ending March 31, 2024',
	'Period ending June 30, 2024',
	'Period ending September 30, 2024',
	'Period ending December 31, 2024',
	'Period ending December 31, 2024 - DRAFT',
];
interface IFile {
	filename: string;
	template: string;
	reportingPeriod: string;
	file: File;
	validData: boolean;
}

export const CSVUpload: FC = () => {
	const grants = useSelector(selectDropdown);
	const { currentFund } = grants.grants;
	const [csvError, setCSVError] = useState(false);
	const [emptyData, setEmptyData] = useState(false);
	const [missingColumn, setMissingColumn] = useState(false);
	const [incorrectType, setIncorrectType] = useState(false);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState<boolean>(false);
	const [errorOpen, setErrorOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [reportingPeriod, setReportingPeriod] = useState('');
	const [files, setFiles] = useState<IFile[]>([]);
	const [invalidFiles, setInvalidFiles] = useState<IFile[]>([]);
	const [notifyInvestorUsers, setNotifyInvestorUsers] =
		useState<boolean>(false);
	const [uploadCSVDocument, { error: uploadError, isSuccess }] =
		useUploadCSVMutation();
	const { notificationDelay } = useSelector(docsUploadState);

	const handleClose = () => {
		setCSVError(false);
		setEmptyData(false);
		setMissingColumn(false);
		setIncorrectType(false);
		setErrorOpen(false);
	};

	const handleRejection = () => {
		setCSVError(true);
		setErrorOpen(true);
	};

	const setPreview = async (acceptedFiles) => {
		setLoading(true);
		if (acceptedFiles.length > 0) {
			const fileList: any[] = await Promise.all(
				acceptedFiles.map(async (file): Promise<any> => {
					return {
						filename: file.name,
						reportingPeriod: reportingPeriod,
						file: file,
						validData: true,
					};
				}),
			);
			fileList.push(...files);
			const filesWithError = fileList.filter((file) => file.validData != true);
			setInvalidFiles(filesWithError);
			setFiles(fileList.filter((file) => !filesWithError.includes(file)));
		}
		acceptedFiles.length = 0;
		setLoading(false);
	};

	const uploadCSV = async () => {
		if (files.length > 0) {
			files.forEach(async (file) => {
				const formData = new FormData();
				formData.append('file', file.file);
				formData.append('reportingPeriod', file.reportingPeriod);
				formData.append('fundId', currentFund.id.toString());
				formData.append('notifyInvestors', JSON.stringify(notifyInvestorUsers));
				formData.append('notificationDelayId', notificationDelay.id.toString());

				uploadCSVDocument({ formData });
			});
		}

		setFiles([]);
	};

	const deleteFile = (file) => {
		const list = files.filter((f) => f !== file);
		setFiles(list);
	};

	const deleteInvalidFile = (file) => {
		const list = invalidFiles.filter((f) => f !== file);
		setInvalidFiles(list);
	};

	useEffect(() => {
		if (uploadError) {
			setSuccess(false);
			setError(true);
		}
	}, [uploadError]);

	useEffect(() => {
		if (isSuccess) {
			setSuccess(true);
			setError(false);
		}
	}, [isSuccess]);

	const DocumentsPreview = () => {
		return (
			<Box>
				<div>
					<TableContainer>
						{files.length > 0 && (
							<Table aria-label="simple table">
								<TableHead sx={{ backgroundColor: '#d4d9ec' }}>
									<TableRow>
										<TableCell>Name</TableCell>
										<TableCell>Reporting Period</TableCell>
										<TableCell />
									</TableRow>
								</TableHead>
								<TableBody>
									{files.map((file, i) => (
										<TableRow key={`${file.filename}-${i}`}>
											<TableCell>{file.filename}</TableCell>
											<TableCell>{file.reportingPeriod}</TableCell>
											<TableCell>
												<IconButton
													color="inherit"
													onClick={() => deleteFile(file)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
						{invalidFiles.length > 0 && (
							<Table>
								<TableHead sx={{ backgroundColor: '#FF313150' }}>
									<TableRow>
										<TableCell>
											<Typography variant="h5">
												These files contain an error and will not be uploaded.
												Please check the contents of the file.
											</Typography>
										</TableCell>
										<TableCell />
										<TableCell>
											<Button
												onClick={() => setInvalidFiles([])}
												endIcon={<DeleteIcon />}
											>
												Remove All
											</Button>
										</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{invalidFiles.map((file, i) => (
										<TableRow key={`${file.filename}-${i}`}>
											<TableCell>{file.filename}</TableCell>
											<TableCell>{file.reportingPeriod}</TableCell>
											<TableCell>
												<IconButton
													color="inherit"
													onClick={() => deleteInvalidFile(file)}
												>
													<DeleteIcon />
												</IconButton>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						)}
					</TableContainer>
				</div>
			</Box>
		);
	};

	const UploadCSVSection = () => {
		return (
			<Box>
				<Dropzone
					accept={{ 'text/csv': ['.csv'] }}
					multiple={false}
					onDrop={(acceptedFiles) => {
						setPreview(acceptedFiles);
					}}
					onDropRejected={handleRejection}
				>
					{({ getRootProps, getInputProps }) => (
						<DropZoneBox {...getRootProps()}>
							<input
								{...getInputProps()}
								onChange={(e: any) => {
									setPreview(e.target.files);
								}}
							/>
							<Typography variant="h4">
								Drag & drop files here, or click to select files
							</Typography>
						</DropZoneBox>
					)}
				</Dropzone>
			</Box>
		);
	};

	const ReportingPeriod = () => (
		<Box>
			<SelectFormControl>
				<SelectInputLabel id="reportingPeriod-dropdown-label">
					Reporting Period
				</SelectInputLabel>
				<Select
					labelId="reportingPeriod-dropdown-label"
					id="reportingPeriod-dropdown"
					value={reportingPeriod}
					label="Select Reporting Period"
					onChange={(e) => setReportingPeriod(e.target.value as string)}
				>
					{ReportPeriods.map((tpl) => (
						<MenuItem value={tpl} key={tpl}>
							{tpl}
						</MenuItem>
					))}
				</Select>
			</SelectFormControl>
		</Box>
	);

	const FileAlert = () => {
		return (
			<Modal
				open={csvError}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Box>
					<ModalText>Error!</ModalText>
					<ModalText>Please Upload valid CSV file</ModalText>
					<ButtonBox>
						<Button
							type="button"
							variant="contained"
							onClick={() => handleClose()}
						>
							Close
						</Button>
					</ButtonBox>
				</Box>
			</Modal>
		);
	};

	const FooterSection = () => {
		return (
			<ButtonContainer>
				<UploadButton onClick={uploadCSV}>Upload</UploadButton>
			</ButtonContainer>
		);
	};

	return (
		<Background>
			<Step variant="h5">Step 3 of 5: CSV Upload</Step>
			{loading && (
				<Backdrop
					sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
					open={loading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			<ReportingPeriodBox>
				<ReportingPeriod />
			</ReportingPeriodBox>
			{csvError && <FileAlert />}
			{emptyData && (
				<Alert severity="error">Data does not exist in uploaded CSV file</Alert>
			)}
			{missingColumn && (
				<Alert severity="error">Data does not exist in uploaded CSV file</Alert>
			)}
			{incorrectType && (
				<Alert severity="error">
					Incorrect data exists in uploaded CSV file
				</Alert>
			)}
			{success && <Alert severity="success">Upload successful</Alert>}
			{error && (
				<Alert severity="error">
					There was an error while uploading your file.
				</Alert>
			)}
			{reportingPeriod && (
				<>
					<UploadCSVSection />
					<DocumentsPreview />
					<ActionsContainer container direction="row" spacing={2}>
						<Grid item>
							<NotifyCheckbox
								checked={notifyInvestorUsers}
								onClick={() => setNotifyInvestorUsers(!notifyInvestorUsers)}
							/>
						</Grid>
						<Grid item>
							<NotificationDelaySelector show={notifyInvestorUsers} />
						</Grid>
					</ActionsContainer>
					<FooterSection />
				</>
			)}
		</Background>
	);
};
