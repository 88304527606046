import { FC, useCallback, useState } from 'react';
import { useGetOrganizationsQuery } from 'api/redux/services/organizations.service';
import AdminSidebar from 'modules/admin/AdminSideBar';
import { Box, Container } from '@mui/material';
import { ManageInvestors } from './ManageInvestors';
import { UploadData } from 'modules/admin/UploadData';
import AdminBreadcrumbs from 'common/components/AdminBreadCrumbs';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import { useGetFundsQuery } from 'api/redux/services/fundApi';
import { UploadDocuments } from 'modules/admin/UploadDocuments/UploadDocuments';
import { selectDropdown } from 'api/redux/DropdownReducer';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/system';
import { onSetSuccessMessage } from 'api/redux/DocsUploadReducer';

const ContentSection = styled(Container)(() => ({
	alignItems: 'center',
	height: '100%',
	overflow: 'auto',
	padding: 30,
	position: 'relative',
}));

const SideBarContainer = styled(Container)(() => ({
	width: '20.5rem',
	marginLeft: 0,
	marginRight: 0,
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	height: '100%',
	position: 'sticky',
}));

const RootContainer = styled(Container)(() => ({
	display: 'flex',
	paddingLeft: '0px !important',
	paddingRight: '0px !important',
	marginRight: 0,
	marginTop: 0,
	maxWidth: '100vw',
	overflow: 'hidden',
	height: 'calc(100vh - 80px)',
}));

export const FundPage: FC = () => {
	const [currentTab, setCurrentTab] = useState('Investors');
	const { data: organizations = [] } = useGetOrganizationsQuery({});
	const grants = useSelector(selectDropdown);
	const { currentSponsor, currentFund } = grants.grants;
	const thisOrganization = organizations.filter(
		(f) => f.id === currentSponsor.id,
	)[0];

	const { data: funds = [] } = useGetFundsQuery(currentSponsor.id);

	const thisFund = funds.filter((f) => f.id === currentFund.id)[0];

	const onClick = (option) => {
		setCurrentTab(option);
	};

	const dispatch = useDispatch();

	const renderTabComponent = useCallback(() => {
		dispatch(onSetSuccessMessage(false));
		switch (currentTab) {
			case 'Edit Investors':
				return <ManageInvestors />;
			case 'Upload Documents':
				return <UploadDocuments />;
			case 'Upload Data':
				return <UploadData />;
			default:
				return <ManageInvestors />;
		}
	}, [currentTab]);

	return (
		<MainSidebarContainer>
			<RootContainer maxWidth={false}>
				<SideBarContainer maxWidth={false}>
					<AdminSidebar onClick={onClick} />
				</SideBarContainer>
				<ContentSection maxWidth={false}>
					<AdminBreadcrumbs
						list={[
							{ text: 'Admin', link: '/admin' },
							{
								text: thisOrganization?.name,
								link: `/superadmin/sponsors`,
							},
							{ text: thisFund?.name },
						]}
					/>
					<Box>{renderTabComponent()}</Box>
				</ContentSection>
			</RootContainer>
		</MainSidebarContainer>
	);
};
