import { FC } from 'react';
import {
	Typography,
	Box,
	Button,
	Dialog,
	DialogTitle,
	DialogActions,
} from '@mui/material';

interface IConfirmationModalProps {
	isOpen: boolean;
	onCancel: () => void;
	onAccept: () => void;
	title?: string;
	message?: string;
	cancelText?: string;
	saveText?: string;
	saveDisabled?: boolean;
}

export const ConfirmationModal: FC<IConfirmationModalProps> = ({
	isOpen,
	onCancel,
	onAccept,
	title,
	message,
	cancelText,
	saveText,
	saveDisabled,
}) => {
	const defaultTitle = 'Unsaved Changes';
	const defaultMessage =
		'You have unsaved changes in the current tab. Would you like to save the changes or discard them?';
	const defaultCancelText = 'Cancel';
	const defaultSaveText = 'Save';

	return (
		<Dialog open={isOpen} onClose={onCancel} fullWidth maxWidth="sm">
			<DialogTitle>{title || defaultTitle}</DialogTitle>
			<Box sx={{ pl: 3, pr: 3, pb: 3 }}>
				<Typography>{message || defaultMessage}</Typography>
				<DialogActions>
					<Button onClick={onCancel}>{cancelText || defaultCancelText}</Button>
					<Button
						variant="contained"
						onClick={onAccept}
						disabled={saveDisabled}
					>
						{saveText || defaultSaveText}
					</Button>
				</DialogActions>
			</Box>
		</Dialog>
	);
};
