import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useResolvedPath } from 'react-router-dom';
import { Box, Pagination, Paper, Typography } from '@mui/material';
import { ERoutes } from 'App';
import { selectDropdown } from 'api/redux/DropdownReducer';
import PermissionsWrapper, {
	hasPermission,
} from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { EditInvestorModal } from 'modules/investor-view/investor-info/EditInvestorModal';
import { IUsersTableListProps } from '../types';
import { UserPermissionsModal } from '../user-permissions/UserPermissions';
import { AddUserModal } from '../add-user-modal/AddUserModal';
import { DeleteUserModal } from '../delete-user-modal/DeleteUserModal';
import { AddUserButton } from './AddUserButton';
import { DownloadUsersButton } from './DownloadUsersButton';
import { BulkUploadUsersButton } from './BulkUploadUsersButton';
import { Investor } from 'app/types/investor.types';
import { useLazyGetInvestorQuery } from 'api/redux/services/investors.service';
import LoadingSpinner from 'common/components/LoadingSpinner';
import CardsTable from 'common/components/CardsTable/CardsTable';
import { IAscDesc } from 'api/redux/types';
import { onSetSortStrategy, usersState } from 'api/redux/UsersReducer';
import { ESortSource } from 'common/components/CardsTable/types';
import { ConfirmationModal } from '../user-permissions/ConfirmationModal';
import { SearchBar } from 'common/components/SearchBar';
import { styled } from '@mui/system';

const TableBox = styled(Box)(() => ({
	width: 'min(100%, 1300px)',
}));

const HeaderPaper = styled(Paper)(() => ({
	width: '100%',
	marginBottom: '3px',
	padding: '5px',
	display: 'flex',
	justifyContent: 'flex-end',
	border: '1px solid #e3e3e3',
}));

const LabelBox = styled(Box)(() => ({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	width: '100%',
	padding: 10,
}));

export const UsersTableList: FC<IUsersTableListProps> = ({
	refetchUsers,
	columns,
	addSponsor,
	setAddSponsor,
	onUserRowClick,
	editUserPermissions,
	setEditUserPermissions,
	editInvestor,
	setEditInvestor,
	selectedInvestorId,
	setSelectedInvestorId,
	selectedUser,
	investors,
	selectedUserId,
	deleteInvestor,
	setDeleteInvestor,
	setPage,
	pages,
	loading,
	searchString,
	setSearchString,
}) => {
	const dispatch = useDispatch();
	const grants = useSelector(selectDropdown);
	const location = useLocation();
	const resolvedPath = useResolvedPath(location.pathname);
	const path = resolvedPath.pathname;
	const { currentInvestor } = grants.grants;
	const [getInvestor, investorData] = useLazyGetInvestorQuery();
	const [investor, setInvestor] = useState<Investor | undefined>(undefined);
	const { users } = useSelector(usersState);
	const [onCancel, setOnCancel] = useState<boolean>(false);
	const [showConfirmationModal, setShowConfirmationModal] =
		useState<boolean>(false);

	useEffect(() => {
		if (currentInvestor.id !== 0) getInvestor(currentInvestor.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentInvestor.id]);

	useEffect(() => {
		if (!showConfirmationModal && !onCancel) {
			setEditUserPermissions(false);
		}
	}, [showConfirmationModal]);

	useEffect(() => {
		if (investorData.data) {
			setInvestor(investorData.data);
		}
	}, [investorData.data]);

	const canAddUser = () => {
		const view = {
			[ERoutes.InvestorsView]: () => currentInvestor.id !== 0,
			[ERoutes.OrganizationView]: () => true,
		};

		return hasPermission({ scopes: [SCOPES.canCreateUsers] }) && view[path]();
	};

	const handleSort = (column: string, ascdesc: IAscDesc) => {
		const sortableColumns: string[] = ['email', 'name', 'role'];

		if (!sortableColumns.includes(column)) return;

		dispatch(onSetSortStrategy({ column, ascdesc }));
	};

	return (
		<TableBox>
			<HeaderPaper elevation={0}>
				<LabelBox>
					<Typography sx={{ color: '#000000' }} variant="h5">
						Users
					</Typography>
				</LabelBox>
				<SearchBar
					value={searchString}
					onChange={(val) => {
						setPage(0);
						setSearchString(val);
					}}
					onCancelSearch={() => {
						setPage(0);
						setSearchString('');
					}}
				/>
				<AddUserButton setAddSponsor={setAddSponsor} canAddUser={canAddUser} />
				{path === ERoutes.OrganizationView && (
					<>
						<PermissionsWrapper scopes={[SCOPES.canDownloadUsers]}>
							<DownloadUsersButton />
						</PermissionsWrapper>
						<PermissionsWrapper scopes={[SCOPES.canBulkUploadUsers]}>
							<BulkUploadUsersButton />
						</PermissionsWrapper>
					</>
				)}
			</HeaderPaper>
			<Box>
				<Box sx={{ width: '100%', height: '75vh', overflow: 'auto' }}>
					{loading ? (
						<LoadingSpinner />
					) : (
						<CardsTable
							columns={columns}
							data={users}
							onSort={handleSort}
							onRowClick={(row) => onUserRowClick(row.original)}
							sortSource={ESortSource.USERS}
						/>
					)}
				</Box>
				<Box
					sx={{
						width: '100%',
						textAlign: 'center',
					}}
				>
					{pages > 1 ? (
						<Pagination
							count={pages}
							variant="outlined"
							onChange={(event: React.ChangeEvent<unknown>, value: number) =>
								setPage(value - 1)
							}
							sx={{
								'&.MuiPagination-root': {
									backgroundColor: 'unset !important',
									justifyContent: 'center',
									display: 'flex',
									boxShadow: 'none',
									fontFamily:
										'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight',
									fontSize: 13,
								},
							}}
						/>
					) : (
						<></>
					)}
				</Box>
			</Box>

			{editUserPermissions &&
				selectedUserId !== undefined &&
				selectedInvestorId !== undefined &&
				selectedUser !== undefined &&
				investors.length > 0 && (
					<UserPermissionsModal
						isOpen={editUserPermissions}
						handleClose={() =>
							onCancel
								? setShowConfirmationModal(onCancel)
								: setEditUserPermissions(false)
						}
						user={selectedUser}
						investors={investors}
						selectedInvestorId={selectedInvestorId}
						setSelectedInvestorId={setSelectedInvestorId}
						onEditUserClick={() => {
							setEditUserPermissions(false);
							setEditInvestor(true);
						}}
						handleReload={refetchUsers}
						onClose={(cancel) => setOnCancel(cancel)}
					></UserPermissionsModal>
				)}
			{canAddUser() && (
				<AddUserModal
					isOpen={addSponsor}
					afterAdd={refetchUsers}
					handleClose={() => setAddSponsor(false)}
				/>
			)}
			{hasPermission({ scopes: [SCOPES.canDeleteUsers] }) && selectedUserId && (
				<DeleteUserModal
					userId={selectedUserId}
					isOpen={deleteInvestor}
					afterDelete={refetchUsers}
					handleClose={() => setDeleteInvestor(false)}
				/>
			)}
			{editInvestor && investor && (
				<EditInvestorModal
					isOpen={editInvestor}
					onClose={() => setEditInvestor(false)}
					investor={investor}
					afterSave={() => setEditInvestor(false)}
					editContactInfo={false}
				></EditInvestorModal>
			)}
			<ConfirmationModal
				isOpen={showConfirmationModal}
				onAccept={() => {
					setEditUserPermissions(true);
					setShowConfirmationModal(false);
				}}
				onCancel={() => {
					setEditUserPermissions(false);
					setOnCancel(false);
					setShowConfirmationModal(false);
				}}
				cancelText="Cancel"
				saveText={`Continue to edit`}
				title={``}
				message={'Changes will not be saved, are you sure you want to cancel?'}
			/>
		</TableBox>
	);
};
