import { FC } from 'react';
import { useMatch } from 'react-router-dom';
import { ISelectInvestorProps } from './types';
import { ERoutes } from 'App';
import {
	Box,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
} from '@mui/material';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { ViewInvestorInfoButton } from 'modules/investor-view/investor-info/ViewInvestorInfoButton';

export const SelectInvestor: FC<ISelectInvestorProps> = ({
	investors,
	selectedInvestorId,
	setSelectedInvestorId,
}) => {
	const isOrganizationPage = useMatch(ERoutes.OrganizationView);

	if (!isOrganizationPage) return <></>;

	return (
		<Grid container direction="row">
			<Grid item>
				<FormControl variant="outlined" size="small" sx={{ m: 1, width: 250 }}>
					<InputLabel id="investor-selector">Investor</InputLabel>
					<Select
						labelId="investor-selector"
						id="investor-selector"
						value={selectedInvestorId}
						onChange={(e) => setSelectedInvestorId(e.target.value as number)}
						label="Investor"
						sx={{ textAlign: 'left' }}
						disabled={investors.length === 1}
					>
						{investors.map((investor, index) => (
							<MenuItem key={index} value={investor.id}>
								{investor.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Grid>
			<Grid item>
				<PermissionsWrapper scopes={[SCOPES.canEditInvestorInfo]}>
					<Box justifyContent="flex-start">
						<ViewInvestorInfoButton
							show={true}
							investorId={selectedInvestorId}
						/>
					</Box>
				</PermissionsWrapper>
			</Grid>
		</Grid>
	);
};
