import { FC } from 'react';
import { MainSidebarContainer } from 'common/components/drawer/MainSidebarContainer';
import PermissionsWrapper from 'common/helpers/permissions/PermissionsWrapper';
import { SCOPES } from 'common/helpers/permissions/Scopes';
import { InvestorInfo } from './investor-info/InvestorInfo';
import { InvestorMembers } from './InvestorsMembers';
import { Box, Grid } from '@mui/material';

export const InvestorView: FC = () => {
	return (
		<MainSidebarContainer>
			<Grid
				container
				spacing={1}
				sx={{
					height: '100%',
					margin: '5px',
					width: '100%',
				}}
			>
				<PermissionsWrapper scopes={[SCOPES.canSeeInvestorInfo]}>
					<InvestorInfo />
				</PermissionsWrapper>

				<InvestorMembers />
			</Grid>
		</MainSidebarContainer>
	);
};
