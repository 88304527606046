import { Typography, TextField, Button, Box } from '@mui/material';
import { useCreateAnnouncementMutation } from 'api/redux/services/announcementsApi';
import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { selectDropdown } from 'api/redux/DropdownReducer';

const Wrapper = styled(Box)(() => ({
	width: '75%',
	marginLeft: 'auto',
	marginRight: 'auto',
	marginTop: 15,
	marginBottom: 15,
	padding: 15,
	justifyContent: 'center',
	textAlign: 'center',
}));

export const CreateAnnouncement: React.FC = () => {
	const { grants } = useSelector(selectDropdown);
	const [createAnnouncement] = useCreateAnnouncementMutation();
	const { currentSponsor } = grants;
	const [text, setText] = useState<string>('');

	const handleSubmit = async () => {
		await createAnnouncement({ text, sponsorId: currentSponsor.id });
		setText('');
	};

	return (
		<Paper>
			<Wrapper>
				<Typography variant={'h3'}>Announcements</Typography>
				<TextField
					multiline
					fullWidth
					variant="outlined"
					value={text}
					onChange={(e) => setText(e.target.value)}
				/>
				<Button
					style={{ marginTop: 15 }}
					variant="contained"
					onClick={handleSubmit}
				>
					Post
				</Button>
			</Wrapper>
		</Paper>
	);
};

export default CreateAnnouncement;
