import { createTheme } from '@mui/material/styles';
import IBMPlexSansRegular from './fonts/IBMPlexSans-Regular.ttf';
import IBMPlexSansBold from './fonts/IBMPlexSans-Bold.ttf';
import IBMPlexSansLight from './fonts/IBMPlexSans-Light.ttf';
import RalewayRegular from './fonts/Raleway-Regular.ttf';

declare module '@mui/material/styles' {
	interface Theme {
		greyscale: {
			100: string;
			200: string;
			300: string;
			400: string;
			500: string;
		};
		buttons: {
			purpleOutline: string;
			primary: string;
			secondary: string;
		};
		sidePanel: {
			dark: string;
			light: string;
		};
		backgrounds: {
			primary: string;
			dark: string;
			light: string;
		};
		fontColors: {
			primary: string;
			secondary: string;
		};
	}
	interface ThemeOptions {
		greyscale?: {
			100?: string;
			200?: string;
			300?: string;
			400?: string;
			500?: string;
		};
		buttons?: {
			purpleOutline?: string;
			primary?: string;
			secondary?: string;
		};
		sidePanel?: {
			dark?: string;
			light?: string;
		};
		backgrounds?: {
			primary?: string;
			dark?: string;
			light?: string;
		};
		fontColors?: {
			primary?: string;
			secondary?: string;
		};
	}
}

const theme = createTheme({
	palette: {
		primary: {
			light: '#D2D7DF',
			main: '#84868A', //'#3f51b5'
			dark: '#5C5E60',
		},
		secondary: {
			light: '#0092D8',
			main: '#0092D8', //'#f50057'
			dark: '#044DA2',
		},
		error: {
			main: '#FF3131',
		},
		background: {
			default: '#FFFFFF',
		},
		text: {
			primary: '#353535',
			secondary: '#5C5E60',
		},
	},
	greyscale: {
		100: '#D2D7DF',
		200: '#ABAFB5',
		300: '#84868A',
		400: '#5C5E60',
		500: '#353535',
	},
	buttons: {
		purpleOutline: '#6559f4',
		primary: '#788896',
		secondary: '#e3e7ea',
	},
	sidePanel: {
		dark: '#c3cfd9',
		light: '#dfe6ee',
	},
	backgrounds: {
		primary: '#FFFFFF',
		dark: '#eff3f6',
		light: '#f8f9fa',
	},
	fontColors: {
		primary: '#293845',
		secondary: '#788896',
	},
	typography: {
		fontFamily:
			'IBMPlexSansRegular, IBMPlexSansBold, IBMPlexSansLight, Raleway',
		fontSize: 12,
		h2: {
			color: '#5C5E60',
		},
		h3: {
			color: '#5C5E60',
		},
		h4: {
			color: '#1F1F1F',
			fontSize: '1.5em',
			fontWeight: 500,
		},
		h5: {
			color: '#5C5E60',
		},
		h6: {
			color: '#5C5E60',
		},
	},
	components: {
		MuiCssBaseline: {
			styleOverrides: `
			@font-face {
			  font-family: 'IBMPlexSansRegular';
			  font-style: normal;
			  font-weight: 400;
			  src: local('IBMPlexSansRegular'), url(${IBMPlexSansRegular}) format('truetype');
			}
			@font-face {
			  font-family: 'IBMPlexSansBold';
			  font-style: normal;
			  font-weight: 700;
			  src: local('IBMPlexSansBold'), url(${IBMPlexSansBold}) format('truetype');
			}
			@font-face {
			  font-family: 'IBMPlexSansLight';
			  font-style: normal;
			  font-weight: 300;
			  src: local('IBMPlexSansLight'), url(${IBMPlexSansLight}) format('truetype');
			}
			@font-face {
			  font-family: 'Raleway';
			  font-style: normal;
			  font-weight: 400;
			  src: local('Raleway'), url(${RalewayRegular}) format('truetype');
			}
			button:focus {
				background-color: inherit;
			  }
			  button:hover {
				background-color: inherit !important;
			  }
		  `,
		},
		MuiSvgIcon: {
			styleOverrides: {
				root: {
					width: '1.8rem',
					height: '1.8rem',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: '#FFFFFF',
				},
			},
		},
	},
});

export default theme;
